import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import routes from './routes'
import { useBaseStore } from '@/store/pinia'
import config, { IS_SUB_DOMAIN } from '@/config'
import { _notice } from '@/utils'
import { getTime } from '@/api/user'


const router = createRouter({
  history: IS_SUB_DOMAIN ? createWebHashHistory() : createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // console.log('savedPosition', savedPosition)
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})
router.beforeEach((to, from) => {
  const baseStore = useBaseStore()
  if (to.path === '/' || to.path === '/home') {
    getTime().then((res) => {
      baseStore.vipExpireTime = res.data.time
    })
    //获取url中携带的token和time参数
    const search = new URLSearchParams(to.query)
    const pay = search.get('to')
    if (pay === 'pay') {
      // baseStore.userToken = token
      // baseStore.vipExpireTime = time
      // baseStore.apiSecret= api_secret
      // baseStore.init()
      _notice('购买成功')
    }
  }

  if (to.path === '/me' ||  to.path === '/publish') {
    if (baseStore.userToken === '') {
      _notice("请登录")
      return '/login'
    }
  }

  if (to.path === '/shop') {
      // 重定向至config.foremUrl
      window.location.href = config.foremUrl
  }

  if (to.path === '/message') {

    if (baseStore.userToken === '') {
      _notice("请登录")
      return '/login'
    }
    window.location.href = config.chatUrl + '?api_secret=' + baseStore.apiSecret
}


  //footer下面的5个按钮，对跳不要用动画
  const noAnimation = ['/', '/home', '/me', '/shop', '/message', '/publish', '/home/live', '/test']
  if (noAnimation.indexOf(from.path) !== -1 && noAnimation.indexOf(to.path) !== -1) {
    return true
  }

  const toDepth = routes.findIndex((v) => v.path === to.path)
  const fromDepth = routes.findIndex((v) => v.path === from.path)
  // const fromDepth = routeDeep.indexOf(from.path)

  if (toDepth > fromDepth) {
    if (to.matched && to.matched.length) {
      const toComponentName = to.matched[0].components?.default.name
      baseStore.updateExcludeNames({ type: 'remove', value: toComponentName })
      // console.log('前进')
      // console.log('删除', toComponentName)
    }
  } else {
    if (from.matched && from.matched.length) {
      const fromComponentName = from.matched[0].components?.default.name
      baseStore.updateExcludeNames({ type: 'add', value: fromComponentName })

      // console.log('后退')
      // console.log('添加', fromComponentName)
    }
  }
  return true
})

export default router
